/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "store";
import moment from "moment";

import Router from "routes/index";

import "assets/css/material-dashboard-react.css?v=1.10.0";
import "moment/locale/pt-br";

moment.locale("pt-br");

const App = () => {
  useEffect(() => {
    const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
    if (trackingId) {
      // Adiciona o script Google Tag Manager dinamicamente
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
      document.head.appendChild(script);

      // Configura o Google Analytics assim que o script é carregado
      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', trackingId);
      };
    }
  }, []);

  return (
    <Provider store={store}>
      <Router />
    </Provider>
  );
};

const container = document.getElementById("root");

const root = createRoot(container);


root.render(
  <App />,
  document.getElementById("root"),
)
